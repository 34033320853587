import { useStaticQuery, graphql } from 'gatsby';

export const useCategories = () => {
  const { allStrapiCategory } = useStaticQuery(
    graphql`
      query FetchCategories {
        allStrapiCategory {
          edges {
            node {
              slug
              name
              description
            }
          }
        }
      }
    `,
  );

  const categories = allStrapiCategory.edges;
  return categories;
};
