// If you don't want to use TypeScript you can delete this file!
import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { useCategories } from '../hooks/useCategories';
import { ArrowCircleRightIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/solid';

import Layout from '../layouts/layout';

const MinorPage = ({ data }) => {
  const categories = useCategories();

  return (
    <Layout seo={data.strapiMinor?.seo}>
      <Link to={'/'} className='flex items-center'>
        <ArrowLeftIcon className='w-6 h-6 text-white' />
        <span className='ml-6 text-xl'>Back to home</span>
      </Link>
      <header className='flex flex-col mt-12 md:flex-row md:items-center'>
        <h1 className='font-bold text-transparent text-7xl md:text-8xl bg-gradient-to-br from-light-purple via-white to-white bg-clip-text'>
          {data.strapiMinor.title.split(' ')[0]}
          <br />
          {data.strapiMinor.title.split(' ')[1]}
        </h1>
        <div className='hidden w-1 h-40 mt-2 ml-8 bg-gray-600 md:block ' />
        <span className='mt-4 text-lg font-semibold md:mt-0 md:ml-8'>
          {data.strapiMinor.description}
        </span>
      </header>

      <section className='mt-20'>
        {categories.map((category, i) => (
          <div className='mt-16' key={i}>
            <Link to={`/minor/${category.node.slug}`}>
              <div className='flex items-center justify-between'>
                <h2 className='text-6xl font-bold underline--magical'>{category.node.name}</h2>
                <ArrowCircleRightIcon className='w-6 h-6 mt-3 ml-8 text-gray-500 hover:text-white' />
              </div>
              <p className='mt-6 line-clamp-3'>{category.node.description}</p>
            </Link>
          </div>
        ))}
      </section>
    </Layout>
  );
};

export const ArticleQuery = graphql`
  query MinorQuery {
    strapiMinor {
      title
      description
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;

export default MinorPage;
